export const SERVER_URL1 = "http://168.138.184.140:8098";
export const SERVER_URL2 = "http://168.138.184.140:8092";

export const API_ENDPOINTS = {
  REGISTER: `${SERVER_URL1}/api/v1/auth-service/register`,
  LOGIN: `${SERVER_URL1}/api/v1/auth-service/authenticate`,
  VALIDATE_TOKEN: `${SERVER_URL1}/api/v1/auth-service/validateToken?`,
  REFRESH_TOKEN: `${SERVER_URL1}/api/v1/auth-service/refresh?token=`,
  GET_CLASSES: `${SERVER_URL2}/api/v1/resource-management/class/all`,
  GET_CLASS_By_ID: `${SERVER_URL2}/api/v1/resource-management/class`,
  GET_ANNOUNCEMENTS: `${SERVER_URL2}/api/v1/resource-management/announcement/all`,
  DELETE_ANNOUNCEMENT: `${SERVER_URL2}/api/v1/resource-management/announcement/delete`,
};
